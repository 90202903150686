import { __decorate } from "tslib";
import { Component, Vue, Ref } from 'vue-property-decorator';
import { PermManageService } from '@/sevices/index';
import { OperateType, SysAdmin } from '../../../typings/perm-manage';
import { CTable } from '@cloudpivot-hermes/common-components';
let PermManage = class PermManage extends Vue {
    constructor() {
        super(...arguments);
        this.OperateType = OperateType;
        this.operateType = OperateType.Add;
        this.isShowAddManager = false;
        /**
         * table列定义
         */
        this.tableColumns = [
            {
                width: 60,
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                fixed: 'left',
                slots: { title: 'indexTitle', name: '序号' },
                scopedSlots: { customRender: 'index' },
            },
            {
                dataIndex: 'userName',
                key: 'userName',
                ellipsis: true,
                width: 200,
                slots: { title: 'userNameTitle', name: '用户姓名' },
                scopedSlots: { customRender: 'userName' },
            },
            {
                dataIndex: 'mobile',
                key: 'mobile',
                ellipsis: true,
                width: 200,
                slots: { title: 'mobileTitle', name: '手机号' },
            },
            {
                dataIndex: 'userCode',
                key: 'userCode',
                ellipsis: true,
                slots: { title: 'userCodeTitle', name: '账号' },
            },
            {
                dataIndex: 'manageRange',
                key: 'manageRange',
                ellipsis: true,
                width: 500,
                slots: { title: 'manageRangeTitle', name: '管理范围' },
                scopedSlots: { customRender: 'manageRange' },
            },
            {
                dataIndex: 'status',
                key: 'status',
                ellipsis: true,
                width: 150,
                slots: { title: 'statusTitle', name: '状态' },
                scopedSlots: { customRender: 'status' },
            },
            {
                width: 160,
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                fixed: 'right',
                slots: { title: 'actionTitle', name: '操作' },
                scopedSlots: { customRender: 'action' },
            },
        ];
        this.managerDetail = {};
        this.roleDetail = {};
    }
    get roleId() {
        return this.$route.query.roleId;
    }
    get isSysAdmin() {
        return this.roleId === SysAdmin.SYS;
    }
    created() {
        this.getRoleDetail();
    }
    backHome() {
        this.$router.push({
            name: 'perm-manage',
        });
    }
    /**
     * 获取列表数据接口
     */
    async getList(params) {
        params.roleId = this.roleId;
        const res = await PermManageService.getManagerList(params);
        if (res.success) {
            res.data = res.data.map((item) => {
                return {
                    ...item,
                    manageRange: item.manageRange?.map((range) => {
                        return {
                            name: range.unitName,
                            id: range.unitId,
                            nodeType: range.type,
                        };
                    }) || [],
                    status: item.status === 'ENABLE',
                };
            });
        }
        return res;
    }
    showAddManager(record) {
        if (record.id) {
            this.operateType = OperateType.Edit;
        }
        else {
            this.operateType = OperateType.Add;
        }
        this.isShowAddManager = true;
        this.managerDetail = record;
    }
    /**
     * 停用和启用
     */
    async enableManager(record) {
        const operateText = record.status ? '停用' : '启用';
        const status = record.status ? 'DISABLE' : 'ENABLE';
        const res = await PermManageService.enableManager({
            id: record.id,
            status: status,
        });
        if (res.success) {
            record.status = !record.status;
            this.$message.success(`管理员已${operateText}`);
        }
    }
    /**
     * 获取角色详情
     */
    async getRoleDetail() {
        const res = await PermManageService.getRoleDetail({
            id: this.roleId,
        });
        if (res.success) {
            this.roleDetail = res.data;
        }
    }
    /**
     * 删除管理员
     */
    async delManager(record) {
        const res = await PermManageService.delManager({
            id: record.id,
        });
        if (res.success) {
            this.$message.success('删除成功');
            this.reload();
        }
    }
    goPage(record) {
        this.$router.push({
            name: 'auth-manager',
            query: {
                id: record.id,
            },
        });
    }
    /**
     * 重载
     */
    reload() {
        this.cTable.getTableList();
    }
};
__decorate([
    Ref()
], PermManage.prototype, "cTable", void 0);
PermManage = __decorate([
    Component({
        name: 'PermManage',
        components: {
            CTable,
            AddManager: () => import('@/components/sys-manage/perm-manage/add-manager.vue'),
            TableDeptUsers: () => import('@/components/sys-manage/perm-manage/table-dept-users.vue'),
        },
    })
], PermManage);
export default PermManage;
