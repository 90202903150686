var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-manager"},[_c('div',{staticClass:"auth-manager-wrapper"},[_c('div',{staticClass:"auth-manager-breadcrumb"},[_c('a-breadcrumb',[_c('a-breadcrumb-item',{attrs:{"href":""}},[_c('span',{on:{"click":_vm.backHome}},[_vm._v("角色列表")])]),_c('a-breadcrumb-item',[_vm._v("授权")])],1)],1),_c('div',{staticClass:"auth-manager-container"},[_c('div',{staticClass:"role-wrapper"},[_c('div',{staticClass:"role-item"},[_c('div',{staticClass:"role-title"},[_vm._v(" 角色名称 ")]),_c('div',{staticClass:"role-value",attrs:{"title":_vm.roleDetail.name}},[_vm._v(" "+_vm._s(_vm.roleDetail.name)+" ")])]),_c('div',{staticClass:"role-item"},[_c('div',{staticClass:"role-title"},[_vm._v(" 角色描述 ")]),_c('div',{staticClass:"role-value",attrs:{"title":_vm.roleDetail.description}},[_vm._v(" "+_vm._s(_vm.roleDetail.description)+" ")])])]),_c('div',{staticClass:"auth-manager-header"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.showAddManager}},[_vm._v(" 新建管理员 ")])],1),_c('div',{staticClass:"table-wrapper"},[_c('CTable',{ref:"cTable",attrs:{"tableColumns":_vm.tableColumns,"isEnableScrollWidth":true,"getList":_vm.getList},scopedSlots:_vm._u([{key:"userName",fn:function(ref){
var record = ref.record;
return [_c('span',{class:{'deleted-status-span': record.isDimission},attrs:{"title":record.userName}},[_c('span',{staticClass:"user-name"},[_vm._v(_vm._s(record.userName))]),(record.isDimission)?_c('span',{staticClass:"deleted-status"},[_vm._v("已作废")]):_vm._e()])]}},{key:"manageRange",fn:function(ref){
var record = ref.record;
return [_c('span',{attrs:{"title":record.manageRange.map(function (item) { return item.name; }).join('、')}},[_vm._v(" "+_vm._s(record.manageRange.map(function (item) { return item.name; }).join(';'))+" ")])]}},{key:"status",fn:function(ref){
var record = ref.record;
return [_c('div',{staticClass:"status"},[_c('div',{staticClass:"change-status ant-switch-small",class:{'disabled-change-status': record.isDimission},on:{"click":function($event){$event.stopPropagation();return _vm.enableManager(record)}}}),_c('a-switch',{attrs:{"size":"small","disabled":record.isDimission},model:{value:(record.status),callback:function ($$v) {_vm.$set(record, "status", $$v)},expression:"record.status"}})],1)]}},{key:"action",fn:function(ref){
var record = ref.record;
return [_c('a-button',{staticClass:"operation-btn",attrs:{"type":"link","size":"small","disabled":record.isDimission || _vm.isSysAdmin},on:{"click":function($event){$event.stopPropagation();return _vm.showAddManager(record)}}},[_vm._v(" 修改管理范围 ")]),_c('a-popconfirm',{attrs:{"title":"您确定删除该管理员吗？","okText":"确定","cancelText":"取消","placement":"topLeft"},on:{"confirm":function($event){return _vm.delManager(record)}}},[_c('a-button',{staticClass:"operation-btn",attrs:{"type":"link","size":"small"}},[_vm._v(" 删除 ")])],1)]}}])})],1)])]),_c('AddManager',{attrs:{"operateType":_vm.operateType,"roleId":_vm.roleId,"managerDetail":_vm.managerDetail},on:{"reload":_vm.reload},model:{value:(_vm.isShowAddManager),callback:function ($$v) {_vm.isShowAddManager=$$v},expression:"isShowAddManager"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }